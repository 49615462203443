/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 152번째 로그에서는 코드앤버터, 구글 맵 타임라인, 하시코프 사용자 모임에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/year_progress"
  }, "Year Progress - Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://timeline.google.com/"
  }, "Google Maps Timeline")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/2383"
  }, "하시코프 사용자 모임 2022년 6월 서울 밋업")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.codenbutter.com/"
  }, "코드앤버터 - 웹사이트를 꾸미는 가장 쉬운 방법")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
